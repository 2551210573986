/* latin */
html {
  height: 100%;
}

@font-face {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 400;
  src: local('Calibri'),
    url(https://fonts.gstatic.com/l/font?kit=J7afnpV-BGlaFfdAhLEY67FIEjg&skey=a1029226f80653a8&v=v10) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
}

::-webkit-scrollbar {
  display: none;
}

#root {
  width: 100vw;
  display: grid;
  grid-template-rows: 1fr auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.left-enter {
  opacity: 0;
  left: 75%;
  height: -8%;
  width: -8%;
}
.left-enter.left-enter-active {
  opacity: 1;
  left: 75%;
  height: 72%;
  width: 27%;
  -webkit-transition: box-shadow 1s, left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
  transition: box-shadow 1s, left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}

.left-leave {
  left: 25%;
  height: 72%;
  opacity: 1;
  width: 27%;
}
.left-leave.left-leave-active {
  left: 25%;
  opacity: 0;
  height: -8%;
  width: -8%;
  line-height: 42%;
  z-index: 0;
  -webkit-transition: box-shadow 1s, left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
  transition: box-shadow 1s, left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}

.right-enter {
  opacity: 0;
  left: 25%;
  height: -8%;
  width: -8%;
}
.right-enter.right-enter-active {
  left: 25%;
  opacity: 1;
  height: 72%;
  width: 27%;
  -webkit-transition: box-shadow 1s, left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
  transition: box-shadow 1s, left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}

.right-leave {
  left: 75%;
  height: 72%;
  opacity: 1;
  width: 27%;
}
.right-leave.right-leave-active {
  left: 75%;
  opacity: 0;
  height: -8%;
  width: -8%;
  line-height: 42%;
  -webkit-transition: box-shadow 1s, left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
  transition: box-shadow 1s, left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}
