.html-content * {
  box-sizing: border-box;
}

.html-content .icon {
  position: relative;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-self: center;
  align-self: center;
  -webkit-justify-content: center;
  justify-content: center;
  fill: currentColor;
}

.html-content .icon,
.html-content .icon > svg {
  width: 1em;
  height: 1em;
}

.html-content .icon svg use {
  pointer-events: none;
}

.html-content .icon--inline {
  top: 0.1em;
}

.html-content .icon-arrow-right {
  top: 0.08em;
  left: -0.3em;
}
.html-content .contentitem-infocard {
  text-indent: 0;
}

.html-content .contentitem-infocard__toggle {
  cursor: pointer;
}

.html-content .contentitem-infocard__toggle:hover {
  color: #df5b57;
}

.html-content .contentitem-infocard__content {
  display: none;
  font-style: normal;
  font-weight: 400;
}

@media (max-width: 660px) {
  .html-content .contentitem-infocard__content {
  }
}

.html-content .contentitem-infocard__content a,
.html-content .contentitem-moment__content a {
  color: #df5b57;
  text-decoration: underline;
}

.html-content .contentitem-infocard.is-open .contentitem-infocard__content {
  display: block;
  overflow: auto;
}

.html-content .contentitem-infocard__toggle-icon {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  font-size: 16px;
  line-height: 0;
  color: #df5b57;
  vertical-align: middle;
  background-color: #f1f1f1;
  border-radius: 50%;
}

.html-content .contentitem-infocard__toggle-icon .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 10px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.html-content .contentitem-infocard.is-open .contentitem-infocard__toggle-icon {
  -webkit-transform: scaleY(-1);
  -ms-transform: scaleY(-1);
  transform: scaleY(-1);
}

.html-content .contentitem-infocard.is-open .contentitem-infocard__toggle-icon.mod-source {
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
}

.html-content .contentitem-infocard__toggle-icon.mod-external,
.html-content .contentitem-infocard__toggle-icon.mod-source {
  background-color: #fff;
  box-shadow: inset 0 0 0 2px #dadada;
}

.html-content .contentitem-infocard__toggle-icon.mod-external::before,
.html-content .contentitem-infocard__toggle-icon.mod-source::before {
  position: absolute;
  top: 0;
  right: 0;
  width: 9px;
  height: 9px;
  background-color: #fff;
  content: none;
}

.html-content .contentitem-infocard__toggle-icon.mod-external .icon,
.html-content .contentitem-infocard__toggle-icon.mod-source .icon {
  font-size: 9px;
}

.html-content .contentitem-infocard__image {
  float: left;
  margin: 10px 20px 0 0;
}

@media (max-width: 660px) {
  .html-content .contentitem-infocard__image {
    margin: 10px 10px 0 0;
  }
}

.html-content .contentitem-infocard__source {
  display: block;
  font-style: italic;
}

.html-content .contentitem-infocard__body ~ .html-content .contentitem-infocard__source {
  margin-top: 10px;
}
